const Pdsp =[
    {
      _id: "677208a6d09fdb0bbc2723ad",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Menu-Kebab",
      description: "Kebab\r\nFrites\r\nBoisson",
      category: "Menu",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677208a6d09fdb0bbc2723ae"
          },
          public_id: "products/m2upxod5qatclyek4jkx",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735526565/products/m2upxod5qatclyek4jkx.png"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2024-12-30T02:42:46.081Z"
      },
      __v: 0
    },
    {
      _id:  "677208e1d09fdb0bbc2723b2",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Menu-Libanais",
      description: "Libanais\r\nFrites\r\nBoisson",
      category: "Menu",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677208e1d09fdb0bbc2723b3"
          },
          public_id: "products/rsqtmgc11stiuvkozel3",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735526625/products/rsqtmgc11stiuvkozel3.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2024-12-30T02:43:45.880Z"
      },
      __v: 0
    },
    {
      _id: "677209bed09fdb0bbc2723c1",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Menu-Sandwich-Poulet",
      description: "Sandwich-Poulet\r\nFrites\r\nBoisson",
      category: "Menu",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677209bed09fdb0bbc2723c2"
          },
          public_id: "products/r7wtauacdy4a3xz9ykob",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735526846/products/r7wtauacdy4a3xz9ykob.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2024-12-30T02:47:26.913Z"
      },
      __v: 0
    },
    {
      _id: "67720a11d09fdb0bbc2723c6",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Menu-Sandwich-Merguez",
      description: "Sandwich-Merguez\r\nFrites\r\nBoisson",
      category: "Menu",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "67720a11d09fdb0bbc2723c7"
          },
          public_id: "products/gwakie6vzcus8i0ndned",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735526928/products/gwakie6vzcus8i0ndned.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2024-12-30T02:48:49.059Z"
      },
      __v: 0
    },
    {
      _id: "677298af18edfe251ce2ea0e",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Sandwich-Poulet",
      description: "Poulet\r\nCrudités\r\nSauce",
      category: "Seul",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677298af18edfe251ce2ea0f"
          },
          public_id: "products/prmllsgdcood5olkbsft",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735563438/products/prmllsgdcood5olkbsft.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2024-12-30T12:57:19.149Z"
      },
      __v: 0
    },
    {
      _id:  "6772991418edfe251ce2ea1d",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Sandwich-Merguez",
      description: "Merguez Bœuf\r\nCrudités\r\nSauce",
      category: "Seul",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "6772991418edfe251ce2ea1e"
          },
          public_id: "products/edaelpqvpeyjxkwtbtfb",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735563540/products/edaelpqvpeyjxkwtbtfb.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2024-12-30T12:59:00.876Z"
      },
      __v: 0
    },
    {
      _id: "6772996f18edfe251ce2ea27",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Kebab",
      description: "Kebab\r\nCrudités\r\nSauce",
      category: "Seul",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "6772996f18edfe251ce2ea28"
          },
          public_id: "products/rsora0f5krufxhe9vhdd",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735563631/products/rsora0f5krufxhe9vhdd.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2024-12-30T13:00:31.524Z"
      },
      __v: 0
    },
    {
      _id: "677299a118edfe251ce2ea2c",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Libanais",
      description: "Kebab\r\nCrudités\r\nSauce",
      category: "Seul",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677299a118edfe251ce2ea2d"
          },
          public_id: "products/pagulw3d6kwbtracuqlg",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735563681/products/pagulw3d6kwbtracuqlg.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2024-12-30T13:01:21.804Z"
      },
      __v: 0
    },
    {
      _id: "67751086c90eb43918e88db7",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Menu-Hamburger",
      description: "Hamburger\r\nFrites\r\nBoisson",
      category: "Menu",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "67751086c90eb43918e88db8"
          },
          public_id: "products/o5cuumpfm2rzgxxcx4lh",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735725189/products/o5cuumpfm2rzgxxcx4lh.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T09:53:10.281Z"
      },
      __v: 0
    },
    {
      _id: "67751105c90eb43918e88dbc",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Hamburger",
      description: "Bœuf\r\nCrudités\r\nCheddar",
      category: "Seul",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "67751105c90eb43918e88dbd"
          },
          public_id: "products/wp3usiktebg3xxyls8rb",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735725316/products/wp3usiktebg3xxyls8rb.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T09:55:17.419Z"
      },
      __v: 0
    },
    {
      _id: "677513d3c90eb43918e88df0",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Frites-petite",
      description: "pomme de terre\r\nsauce",
      category: "Frites",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677513d3c90eb43918e88df1"
          },
          public_id: "products/mzvsmbjnrw9owwmbuaqd",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735726034/products/mzvsmbjnrw9owwmbuaqd.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:07:15.662Z"
      },
      __v: 0
    },
    {
      _id: "6775141bc90eb43918e88df5",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Frites-moyenne",
      description: "pomme de terre\r\nsauce",
      category: "Frites",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "6775141bc90eb43918e88df6"
          },
          public_id: "products/sdcduwuwzroba7h08ale",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735726107/products/sdcduwuwzroba7h08ale.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:08:27.464Z"
      },
      __v: 0
    },
    {
      _id: "67751472c90eb43918e88dfa",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Frites-grande",
      description: "pomme de terre\r\nsauce",
      category: "Frites",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "67751472c90eb43918e88dfb"
          },
          public_id: "products/foo9oeywxyxdubkpimyk",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735726193/products/foo9oeywxyxdubkpimyk.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:09:54.162Z"
      },
      __v: 0
    },
    {
      _id: "677514a1c90eb43918e88dff",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Coca",
      description: "Coca",
      category: "Boisson",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677514a1c90eb43918e88e00"
          },
          public_id: "products/u5pihskroqw4hi9e5vvf",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735726240/products/u5pihskroqw4hi9e5vvf.png"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:10:41.194Z"
      },
      __v: 0
    },
    {
      _id: "67751551c90eb43918e88e09",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Fanta",
      description: "Fanta",
      category: "Boisson",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "67751551c90eb43918e88e0a"
          },
          public_id: "products/phwbtpdzhpgwhybjbai7",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735726417/products/phwbtpdzhpgwhybjbai7.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:13:37.768Z"
      },
      __v: 0
    },
    {
      _id: "6775157cc90eb43918e88e0e",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Oasis",
      description: "Oasis",
      category: "Boisson",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "6775157cc90eb43918e88e0f"
          },
          public_id: "products/ldekjxc5mrxko1coftsb",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735726459/products/ldekjxc5mrxko1coftsb.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:14:20.232Z"
      },
      __v: 0
    },
    {
      _id:"6775173a7c1bdb3f64c922c2",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Sauce Ketchup",
      description: "Sauce Ketchup",
      category: "Sauce",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "6775173a7c1bdb3f64c922c3"
          },
          public_id: "products/ywyu0p9tvwljfcntds1t",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735726906/products/ywyu0p9tvwljfcntds1t.webp"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:21:46.920Z"
      },
      __v: 0
    },
    {
      _id: "677517677c1bdb3f64c922c7",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Sauce Mayonnaise",
      description: "Sauce Mayonnaise",
      category: "Sauce",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677517677c1bdb3f64c922c8"
          },
          public_id: "products/wkchok2rqtsixepsgldn",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735726951/products/wkchok2rqtsixepsgldn.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:22:31.984Z"
      },
      __v: 0
    },
    {
      _id:"677517a87c1bdb3f64c922cc",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Sauce Samourai",
      description: "Sauce Samourai",
      category: "Sauce",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677517a87c1bdb3f64c922cd"
          },
          public_id: "products/wnowr8as34ybyqedpw8q",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735727015/products/wnowr8as34ybyqedpw8q.jpg"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:23:36.058Z"
      },
      __v: 0
    },
    {
      _id: "677517e07c1bdb3f64c922d1",
      price: 1,
      ratings: 0,
      stock: 1,
      numOfReviews: 0,
      name: "Sauce Americaine",
      description: "Sauce Americaine",
      category: "Sauce",
      seller: "pat",
      images: [
        {
          _id: {
            $oid: "677517e07c1bdb3f64c922d2"
          },
          public_id: "products/fkymhd8x40ipotauehor",
          url: "https://res.cloudinary.com/dzhmutdg2/image/upload/v1735727072/products/fkymhd8x40ipotauehor.webp"
        }
      ],
      user: {
        $oid: "676c5d4425ed4d1630e32b65"
      },
      reviews: [],
      createdAt: {
        $date: "2025-01-01T10:24:32.478Z"
      },
      __v: 0
    }
  ]
  export default Pdsp
